<template>
  <div style="width: 100%;">
    <v-app>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        Post created
      </v-snackbar>
      <createPosts :newPost="show" @closeModal="closeModal($event)" @getResponse="responseData($event)"/>
      <v-data-table
        :headers="headers"
        :items="posts"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{posts.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: 400px;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')" readonly/>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon :color="'#0a43ae'" @click="deletePost(item.id,item.title)">delete</v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Posts</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              style="text-transform:none;"
              @click="modalbtn"
            >
              New Post
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import createPosts from './createPosts'
import Swal from 'sweetalert2'

export default {
  components: {
    createPosts,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Created on', value: 'created_at' },
      { text: '', value: 'action' },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    // editedItem: {
    //   title: '',
    // },
    state: '',
    id: '',
    posts: [],
    show: false,
    loading: true,
    appear: '',
  }),
  created () {
    this.fetchPosts()
  },
  methods: {
    editMode: function (id, key) {
      var level = document.getElementById(key + '' + id)
      level.focus()
      level.readOnly = false
      level.classList.add('editMode')
    },
    blurMode (id, key) {
      var post = document.getElementById(key + '' + id)
      post.readOnly = true
      post.classList.remove('editMode')
      this.editPost(id, key, post.value)
    },
    async editPost (id, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_post/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    modalbtn () {
      this.show = true
    },
    closeModal (event) {
      this.show = event
    },
    async edit (key) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: this.levelId,
        key: key,
        value: this.dept,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_post/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.dialogModal = false
          this.fetchPosts()
          this.selected = ''
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    responseData (event) {
      this.appear = true
      this.posts.push(event)
      this.show = false
    },
    fetchPosts () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      this.axios({
        url: 'get_posts',
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      })
        .then((response) => {
          // Then injecting the result to datatable parameters.
          this.loading = false
          this.posts = response.data
          return response.data.levels
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.posts = []
          }
        })
    },
    deletePost (id, title) {
      this.$confirm(
        {
          message: 'Delete ' + ' ' + title + ' ' + 'from list ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              // ... do something
              if (localStorage.getItem('logged_user')) {
                this.logger = JSON.parse(localStorage.getItem('logged_user'))
              }
              this.header = this.logger.accessToken
              try {
                const res = await this.axios({
                  url: 'delete_post',
                  method: 'post',
                  data: {
                    id: id,
                  },
                  headers: {
                    Authorization: 'Bearer ' + this.header,
                  },
                })
                if (res.status === 200) {
                  this.posts = this.posts.filter(post => post.id !== id)
                  this.loading = false
                  this.fetchPosts()
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'login-page' })
                }
                if (error.response && error.response.status === 400) {
                  Swal.fire({
                    title: 'Error!',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  })
                  this.loading = false
                } else {
                  console.log(error)
                }
              }
            }
          },
        },
      )
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
