var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-app',[(_vm.appear)?_c('v-snackbar',{attrs:{"timeout":3000,"value":true,"absolute":"","top":"","color":"success","right":"","text":""}},[_vm._v(" Post created ")]):_vm._e(),_c('createPosts',{attrs:{"newPost":_vm.show},on:{"closeModal":function($event){return _vm.closeModal($event)},"getResponse":function($event){return _vm.responseData($event)}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.posts,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"white","font-weight":"600","width":"25px","padding":"8px"},attrs:{"small":"","color":"#9c9c9c"}},[_vm._v(" "+_vm._s(_vm.posts.map(function(x) {return x.id; }).indexOf(item.id)+1)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('input',{staticStyle:{"width":"400px"},attrs:{"type":"text","id":'title'+item.id,"readonly":""},domProps:{"value":item.title},on:{"dblclick":function($event){return _vm.editMode(item.id,'title')},"blur":function($event){return _vm.blurMode(item.id,'title')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.blurMode(item.id,'title')}}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":'#0a43ae'},on:{"click":function($event){return _vm.deletePost(item.id,item.title)}}},[_vm._v("delete")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Posts")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",staticStyle:{"text-transform":"none"},attrs:{"color":"primary","dark":""},on:{"click":_vm.modalbtn}},[_vm._v(" New Post ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mt-2",attrs:{"small":""},on:{"click":function($event){_vm.edit(item),_vm.state='edit'}}})]}}])})],1),_c('vue-confirm-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }