<template>
  <v-dialog
    v-model="newPost"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Create Post</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="swicthModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validate"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'modalTest',
  props: {
    newPost: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      logger: [],
      header: '',
      title: '',
      departments: [],
      state: '',
      id: '',
      valid: true,
    }
  },
  created () {

  },
  methods: {
    swicthModal: function () {
      this.title = ''
      this.valid = false
      this.$emit('closeModal', false)
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.saveData()
        } else {
          // form have errors
        }
      })
    },
    async saveData () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.title === '') {
        this.validate()
      } else {
        const data = {
          title: this.title,
        }
        try {
          const res = await this.axios({
            url: 'manipulate_post',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.title = ''
            this.valid = false
            this.swicthModal()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            localStorage.removeItem('logged_user')
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.title = ''
          } else {
            console.log(e)
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
